
import SuccessMessageSVG from "../public/images/message-icons/success.svg";
import WarningMessageSVG from "../public/images/message-icons/warning.svg";
import InfoMessageSVG from "../public/images/message-icons/info.svg";
import ErrorMessageSVG from "../public/images/message-icons/error.svg";
import NormalMessageSVG from "../public/images/message-icons/message.svg";

export const SuccessMessageIcon = SuccessMessageSVG;
export const WarningMessageIcon = WarningMessageSVG;
export const InfoMessageIcon = InfoMessageSVG;
export const ErrorMessageIcon = ErrorMessageSVG;
export const NormalMessageIcon = NormalMessageSVG;