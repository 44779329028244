import { Col, Row, Typography } from "antd";
import { LayoutProps } from "../types/pageWithLayouts";
import LaptopImage from "../public/images/macbook.png";
import Image from "next/image";

const { Title, Text } = Typography;

const AuthLayout: LayoutProps = ({ children }) => {
  return (
    <>
      <div style={{ height: "100vh" }}>
        <Row style={{ height: "100vh" }}>
          <Col
            style={{
              flex: 1,
              // padding: "5% 5% 5% 5%",
              padding: "5vh 10vw 5vh 10vw",
              height: "100vh",
              // display: "flex",
              // flexDirection: "column",
              // justifyContent: "center",
              // overflow: "scroll",
            }}
          >
            {children}
          </Col>
          <Col
            style={{
              flex: 1,
              padding: "5vh 10vw 0vh 10vw",
              // padding: "5% 0% 1% 0%",
              backgroundColor: "#282E38",
              overflow: "hidden",
            }}
          >
            <div>
              <Title
                style={{
                  color: "white",
                  fontFamily: "Inter",
                  fontSize: "15px",
                }}
              >
                "Leadership belongs to those who take it."
              </Title>
              <Text
                type="secondary"
                style={{
                  fontSize: "10px",
                  color: "rgba(255,255,255,0.5)",
                  fontFamily: "Inter",
                }}
              >
                - Sheryl Sandberg
              </Text>
            </div>
            <div
              style={{
                width: "75vw",
                position: "absolute",
                left: "0px",
              }}
            >
              <Image src={LaptopImage} objectFit={"contain"} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AuthLayout;
