import axios from "axios";

export async function login(data: any) {
    return axios.post(`${process.env.NEXT_PUBLIC_APP_API_URL}/auth/local`, {
        identifier: data.identifier,
        password: data.password,
    }, { headers: { 'Content-Type': 'application/json' } });
}

export async function signUp(data: any) {
    return axios.post(`${process.env.NEXT_PUBLIC_APP_API_URL}/auth/local/register`, {
        username: data.email,
        email: data.email,
        password: data.password,
    }, { headers: { 'Content-Type': 'application/json' } });
}

export async function loginWithGoogle(accessToken: string) {
    return axios.get(`${process.env.NEXT_PUBLIC_APP_API_URL}/auth/google/callback`, {
        params: {
            access_token: accessToken,
        }
    });
}

export async function requestResetPassword(email: string) {
    return axios.post(`${process.env.NEXT_PUBLIC_APP_API_URL}/auth/forgot-password`, {
        email: email,
    }, { headers: { 'Content-Type': 'application/json' } });
}

export async function resetPassword(code: string, password: string, confirmPassword: string) {
    return axios.post(`${process.env.NEXT_PUBLIC_APP_API_URL}/auth/reset-password`, {
        code: code,
        password: password,
        passwordConfirmation: confirmPassword,
    }, { headers: { 'Content-Type': 'application/json' } });
}


export async function confirmUser(token: string) {
    return axios.get(`${process.env.NEXT_PUBLIC_APP_API_URL}/auth/email-confirmation`, {
        params: {
            confirmation: token,
        }
    });
}

